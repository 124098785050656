import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import { findMedia } from '@interness/web-core/src/components/_helpers';

import { H3 } from '../components/styles';

function BaumwollbaenderPage(props) {
  const pdf = findMedia(props.data.pdf.media, '3-baumwollband').publicURL;
  const muster = findMedia(props.data.images.media, 'baumwollband').childImageSharp.fixed;
  return (
    <>
      <HeaderMedia id={'baumwollbaender'}/>
      <Wrapper>
        <Spacer/>
        <Heading>Baumwollbänder</Heading>
        <Spacer height={20}/>
        <p>
          Die Samenfaser der Baumwoll-Pflanze (Cotton) dient als Grundlage unserer Baumwollbänder. Die Bänder haben ein
          mattes Aussehen, sind leicht zu reinigen, wasch- und kochfest. Farb- und schweißechte Färbung sind möglich.
          Unsere Baumwollbänder haben gute Nass- und Trockenreißfestigkeit.
        </p>
        <Separator/>
        <H3>Muster</H3>
        <div style={{ textAlign: 'center' }}>
          <Img fixed={muster} alt='glasband'/>
        </div>
        <Separator/>
        <H3>Spezifikation</H3>
        <table>
          <tbody>
          <tr>
            <td><b>Bezeichnung</b></td>
            <td>Baumwollband</td>
          </tr>
          <tr>
            <td><b>Material</b></td>
            <td>Baumwolle</td>
          </tr>
          <tr>
            <td><b>Gewebebindung</b></td>
            <td>LW - Bindung</td>
          </tr>
          <tr>
            <td><b>Gewebestellung</b></td>
            <td>Kette : 31 Fd Tex 50 per cm,
              Schuß : 8x2 Fd Tex 12 per cm
            </td>
          </tr>
          <tr>
            <td><b>Gewicht</b></td>
            <td>155 g / 100m / per cm</td>
          </tr>
          <tr>
            <td><b>Reißfestigkeit</b></td>
            <td>220 N per cm</td>
          </tr>
          <tr>
            <td><b>Reißdehnung</b></td>
            <td>ca. 6%</td>
          </tr>
          </tbody>
        </table>
        <a href={pdf} target='_blank'>Download Datenblatt (PDF)</a>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default BaumwollbaenderPage;

export const query = graphql`
    query {
        pdf: directusMediaCollection(name: {eq: "pdf"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                    }
                }
            }
        }
        images: directusMediaCollection(name: {eq: "muster"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;